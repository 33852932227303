.App {
  text-align: center;
  background-color: #f5f7fb;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-logo {
  margin: 40px 0;
  width: 75%;
  pointer-events: none;
}

.App .Outro {
  max-width: 720px;
  width: calc(100% - 30px);
  margin: 0 15px;
  font-size: 1.05rem;
  text-align: justify;
}

.App .Team {
  max-width: 1040px;
  margin-top: 60px;
  padding-bottom: 20px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: scroll;
}

.App .Team .Member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.App .Team .Member img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 15px;
  width: 100px;
  height: 100px;
  transition: border-radius .2s ease;
}

.App .Team .Member:hover img {
  width: 100%;
  height: 100%;
  border-radius: 15%;
  width: 100px;
  height: 100px;
}

.App .Team .Member .MemberInfo {
  margin-top: 10px;
}

.App .Team .Member .MemberInfo .MemberLinks {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .App .Outro {
    margin: 0 auto;
  }

  .App .Team {
    flex-direction: row;
  }
}

@media screen and (min-width: 1040px) {
  .App .Team {
    justify-content: center;
    overflow: unset;
    margin-bottom: 0;
  }
}
